import Slider, { Slide } from "../ui/swiper/Slider";
import { SlideOne as SlideItem } from "./slide/SlideOne";

const SliderOne = ({ data, animate, settings, className }) => {
  return (
    <Slider animate={animate} settings={settings} className={className}>
      {data.map((slide) => (
        <Slide key={slide.id}>
          <SlideItem
            title={slide.title}
            thumb={slide.thumb}
            content={slide.content}
            subTitle={slide.subtitle}
            position={slide.position}
          />
        </Slide>
      ))}
    </Slider>
  );
};

export default SliderOne;
