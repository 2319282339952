import cn from "classnames";
import { Container } from "../../styled/bootstrap";
import Category from "../category/Category.js";
import { CategoriesWrap } from "./categories.style";
import Slider, { Slide } from "../../components/ui/swiper/Slider";
import EmptyProduct from "../../components/ui/empty/Empty";

const Categories = ({ categories, className, ...props }) => {
  const settings = {
    loop: false,
    slidesPerView: 2,
    spaceBetween: 5,
    autoplay: false,
    pagination: false,
    navigation: false,
    breakpoints: {
      420: {
        spaceBetween: 10,
      },
      500: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },
  };

    return (
        <CategoriesWrap
            py={[60, 60, 100]}
            className={cn(className)}
            {...props}
        >
            <Container>
                {categories.length > 0 ? (
                    <Slider
                        settings={settings}
                    >
                        {categories?.slice(0, categories.length - 1).map((category) => (
                         
                            <Slide key={category?.text}>
                                <Category
                                    category={category?.text}
                                    icon={category?.image?.originalSrc}
                                    slug={category?.link}
                                />
                            </Slide>
                        ))}
                    </Slider>
                ) : (
                    <EmptyProduct className="mt-0" message="Collections not found!"/>
                )}
            </Container>
        </CategoriesWrap>
    );
};

export default Categories;
