import Header from "./header/Header";
import Footer from "./footer/Footer";
import SearchForm from "../ui/search/SearchForm";
import { Fragment, useState } from "react";
import { Main } from "./header/style";
import SettingsSidebar from "./settings/SettingsSidebar";
import MobileNavbar from "./navbar/MobileNavbar";

const Layout = ({ children, bg, ...props }) => {
  const [isShowConfig, setIsShowConfig] = useState(false);
  const [isShowSearchBox, setIsShowSearchBox] = useState(false);
  const [isShowMobileNav, setIsShowMobileNav] = useState(false);

  const onSearchBoxHandler = () => {
    document.querySelector("body").classList.toggle("overflow");
    setIsShowSearchBox((prevState) => !prevState);
  };

  const onMobileNavHandler = () => {
    document.querySelector("body").classList.toggle("overflow");
    setIsShowMobileNav((prevState) => !prevState);
  };

  const onConfigHandler = () => {
    document.querySelector("body").classList.toggle("overflow");
    setIsShowConfig((prevState) => !prevState);
  };

  return (
    <Fragment>
      <Header
        bg={bg}
        onConfigHandler={onConfigHandler}
        onSearchBoxHandler={onSearchBoxHandler}
        onMobileNavHandler={onMobileNavHandler}
      />

      <MobileNavbar isOpen={isShowMobileNav} onHandler={onMobileNavHandler} />

      <SearchForm isShow={isShowSearchBox} onHandler={onSearchBoxHandler} />

      <SettingsSidebar isOpen={isShowConfig} onHandler={onConfigHandler} />

      <Main {...props}>
        {children}
        <Footer mt={[60, null, 100]} />
      </Main>
    </Fragment>
  );
};

Layout.defaultProps = {
  bg: "secondary",
};

export default Layout;
