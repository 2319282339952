import { useState } from "react";

const useProduct = (product) => {
  const [isShowQuickView, setIsShowQuickView] = useState(false);

  const onQuickViewHandler = () =>
    setIsShowQuickView((prevState) => !prevState);

  return {
    isShowQuickView,
    onQuickViewHandler,
  };
};

export default useProduct;
