import Tooltip from "../../ui/tooltip/Tooltip";
import { ActionButton } from "./product.style";
import { AiOutlineFullscreen } from "react-icons/ai";

const ProductActions = ({ product, onQuickViewHandler }) => {
  const { id } = product;

  return (
    <div className="btn-action">
      <ActionButton
        className="quickview"
        id={`quickview-button-${id}`}
        onClick={() => onQuickViewHandler()}
      >
        <AiOutlineFullscreen />
      </ActionButton>
      <Tooltip target={`quickview-button-${id}`}>Quick View</Tooltip>
    </div>
  );
};

export default ProductActions;
