import cn from "classnames";
import { Link } from "react-router-dom";
import Image from "../../ui/image/Image";
import { ProductImage } from "./product.style";

const ProductThumbnail = ({ product, set }) => {
  const { title, images, id } = product;

  return (
    <ProductImage>
      <Link to={!set && `/product/${id}`}>
        {images?.length > 0 ? (
          images?.slice(0, 2).map((thumb, idx) => (
            <div
              key={idx}
              className={cn("thumb", { "hover-image": idx === 1 })}
            >
              <Image alt={title} width={270} src={thumb.originalSrc} />
            </div>
          ))
        ) : (
          <Image
            alt={title}
            width={270}
            height={318}
            src="/images/about/no-image.webp"
          />
        )}
      </Link>
    </ProductImage>
  );
};

export default ProductThumbnail;
