import { Container, Col, Row } from "../../../styled/bootstrap";
import ProductDetailsThumb from "./Thumbnail";
import ProductDetailsContent from "./Content";
import { ProductDetailsWrapper } from "./details.style";

const ProductDetails = ({ product, ...props }) => {
  return (
    <ProductDetailsWrapper className="product-details-content" {...props}>
      <Container>
        <Row>
          <Col md={6} lg={5}>
            <ProductDetailsThumb thumbnails={product?.images} />
          </Col>

          <Col md={6} lg={7}>
            <ProductDetailsContent product={product} className="details-page" />
          </Col>
        </Row>
      </Container>
    </ProductDetailsWrapper>
  );
};

export default ProductDetails;
