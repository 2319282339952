import cn from "classnames";
import { Link } from "react-router-dom";
import Image from "../../ui/image/Image";
import Button from "../../ui/button/Button";
import { PostItem, PostContent, PostMeta, PostTitle } from "./post.style";

const Post = ({ post, className, ...props }) => {
  const { title, excerpt, image, authorV2, link } = post;

  return (
    <PostItem className={cn(className)} {...props}>
      <Link to={link}>
        <Image
          alt={title}
          width={400}
          height={250}
          layout="responsive"
          src={image?.originalSrc}
        />
      </Link>

      <PostContent>
        <PostTitle>
          <Link to={link}>{title}</Link>
        </PostTitle>

        <PostMeta>
          By <Link to="/">{authorV2?.name}</Link>
        </PostMeta>

        <p>{excerpt}</p>

        <Button
          tag="a"
          href={link}
          bg="text"
          color="white"
          hvrBg="primary"
          hvrColor="white"
          className="btn-read"
          p={[null, null, "15px 25px"]}
        >
          Shop Now
        </Button>
      </PostContent>
    </PostItem>
  );
};

export default Post;
