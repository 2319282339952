import Image from "../ui/image/Image";
import { CategoryItem, CatItemInner, CatName } from "./category.style";
import { Link } from "react-router-dom";

const Category = ({ category, icon, slug }) => {
  return (
    <Link to={slug}>
      <CategoryItem>
        <CatItemInner>
          <Image
            width={65}
            height={65}
            alt={category}
            src={icon ? icon : "/images/about/no-image.webp"}
          />
          <CatName>{category}</CatName>
        </CatItemInner>
      </CategoryItem>
    </Link>
  );
};

export default Category;
