import cn from "classnames";
import parse from "react-html-parser";
import { Link } from "react-router-dom";
import Image from "../ui/image/Image";
import { PromoItem, PromoInfo, PromoContent, PromoTitle } from "./promo.style";

const Promo = ({ title, content, thumb, slug, className, align }) => {
  return (
    <Link to={slug}>
      <PromoItem className={cn(className)}>
        <figure>
          <Image
            src={thumb}
            alt={title}
            width={570}
            height={290}
            layout="responsive"
          />
        </figure>
        <PromoInfo align={align ? align : "left"}>
          <PromoContent>
            <PromoTitle>{parse(title)}</PromoTitle>
          </PromoContent>
        </PromoInfo>
      </PromoItem>
    </Link>
  );
};

export default Promo;
