import cn from "classnames";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { useProduct } from "../../../hooks/Hooks";
import ProductActions from "./Actions";
import ProductThumbnail from "./Thumbnail";
import QuickView from "../card/QuickView";
import {
  Product,
  ProductMeta,
  ProductThumb,
  ProductTitle,
  ProductActionsMobile,
  ProductActions as ActionsWrap,
} from "./product.style";

const ProductCard = ({ product, className, set }) => {
  let { title, id } = product;
  console.log(set)

  const { isShowQuickView, onQuickViewHandler } = useProduct(product);

  return (
    <Fragment>
      <Product className={cn(className)}>
        <ProductThumb>
          <ProductThumbnail product={product} set={set}/>

          <ActionsWrap>
            <ProductActions
              product={product}
              onQuickViewHandler={onQuickViewHandler}
            />
          </ActionsWrap>
        </ProductThumb>

        <ProductMeta>
          <ProductTitle>
            <Link to={!set && `/product/${id}`}>{title}</Link>
          </ProductTitle>
        </ProductMeta>

        <ProductActionsMobile>
          <ProductActions
            product={product}
            onQuickViewHandler={onQuickViewHandler}
          />
        </ProductActionsMobile>
      </Product>

      {isShowQuickView && (
        <QuickView
          product={product}
          isOpen={isShowQuickView}
          onHandler={onQuickViewHandler}
        />
      )}
    </Fragment>
  );
};

export default ProductCard;
