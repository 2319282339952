// import { IoLogoTwitter, IoLogoLinkedin, IoLogoFacebook } from "react-icons/io";
import { ContentWrap, ProductName } from "./details.style";

const ProductDetailsContent = ({ product, ...props }) => {
  let { title, description } = product;

  return (
    <ContentWrap {...props}>
      <ProductName>{title}</ProductName>

      <p>{description}</p>

      {/* <ProductSocialShare>
        <h4>Share:</h4>
        <div className="media">
          <a href="https://facebook.com" target="_blank" rel="noreferrer">
            <IoLogoFacebook />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noreferrer">
            <IoLogoTwitter />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noreferrer">
            <IoLogoLinkedin />
          </a>
        </div>
      </ProductSocialShare> */}
    </ContentWrap>
  );
};

export default ProductDetailsContent;
