import Button from "../../ui/button/Button";
import { Col, Container, Row } from "../../../styled/bootstrap";
import {
  SlideContent,
  SlideItem,
  SlideSubTitle,
  SlideTitle,
  // SliderThumb,
} from "../slider.style";

const SlideOne = ({ subTitle, title, content, thumb, position }) => {
  return (
    <SlideItem style={{ backgroundImage: `url(${thumb})`}}>
      <Container>
        <Row className={position === "start" ? "align-items-center justify-content-start" : "align-items-center justify-content-end"}>
          <Col
            xs={{ order: 1 }}
            sm={{ size: 7, order: 0 }}
            xl={{ size: 6, order: 0 }}
          >
            <SlideContent className="slide-content">
              {subTitle && <SlideSubTitle>{subTitle}</SlideSubTitle>}
              {title && <SlideTitle>{title}</SlideTitle>}
              {content && <p>{content}</p>}
              <Button
                tag="a"
                href="/shop"
                color="white"
                bg="primary"
                hvrBg="black"
                hvrColor="primary"
                className="mt-4 mt-md-5"
              >
                Shop Now
              </Button>
            </SlideContent>
          </Col>
        </Row>
      </Container>
    </SlideItem>
  );
};

export { SlideOne };
