import { Container, Col, Row } from "../../styled/bootstrap";
import {
  ContactMap,
  ContactWrapper,
  ContactInfoItem,
  ContactContentWrap,
  ContactContentTitle,
  ContactInfoMethod,
  ContactInfoText,
} from "./contact.style";

const Contact = (props) => {
  return (
    <ContactWrapper {...props}>
      <Container>
        <ContactMap mb={60}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31838.341125161962!2d9.694344967549274!3d4.0626458609263025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10611208d1586b1b%3A0x8b670a28e3da7f81!2sDouala%20Beach!5e0!3m2!1sen!2slb!4v1683097457369!5m2!1sen!2slb"
            width="600"
            height="450"
            style={{border:0}}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </ContactMap>

        <Row>
          <Col lg={12}>
            <ContactContentWrap className="h-100">
              <ContactContentTitle>Contact Info</ContactContentTitle>

              <ContactInfoItem>
                <ContactInfoMethod>Phone:</ContactInfoMethod>
                <ContactInfoText>
                  <p>
                    <a href="tel:+00237670000050">+00237670000050</a>
                  </p>
                </ContactInfoText>
              </ContactInfoItem>

              <ContactInfoItem>
                <ContactInfoMethod>Email:</ContactInfoMethod>
                <ContactInfoText>
                  <p>
                    <a href="mailto:unico@colorceramica.com">
                      unico@colorceramica.com
                    </a>
                  </p>
                </ContactInfoText>
              </ContactInfoItem>

              <ContactInfoItem>
                <ContactInfoMethod>Address:</ContactInfoMethod>
                <ContactInfoText>
                  <p>AKWA BEACH</p>
                  <p>Douala, Cameroun</p>
                </ContactInfoText>
              </ContactInfoItem>
            </ContactContentWrap>
          </Col>
        </Row>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;
