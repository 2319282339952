import { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import BootstrapProvider from "./styled/bootstrap";
import { ThemeProvider, theme } from "./styled/styled";
import { GlobalStyle } from "./assets/css/global.style";

import Home from "./pages/Home.js";
import AboutPage from "./pages/AboutPage.js";
import ContactPage from "./pages/Contact";
import ShopPage from "./pages/shop/ShopPage";

import ProductDetailsPage from "./pages/product/Productdetail";

// CSS import
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

// Fonts Import
import "@fontsource/raleway";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";
import "@fontsource/raleway/300-italic.css";
import "@fontsource/open-sans";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";

// Customize Bootstrap
const themeBootstrap = {
  "$container-max-widths": {
    sm: "540px",
    md: "720px",
    lg: "960px",
    xl: "1200px",
  },
};

function App() {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <BootstrapProvider theme={themeBootstrap} reset={true}>
          <GlobalStyle />
          <Router>
            <Suspense>
              <Routes>
                <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  element={<AboutPage />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  element={<ContactPage />}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  element={<ShopPage />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/search/:search"}
                  element={<ShopPage />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop/:name"}
                  element={<ShopPage />}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/shop/:name/:sub"}
                  element={<ShopPage />}
                />
                />

                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  element={<ProductDetailsPage />}
                />
              </Routes>
            </Suspense>
          </Router>
        </BootstrapProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
