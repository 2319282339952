import cn from "classnames";
import Loader from "../../ui/loader/Loader";
import { Col, Container, Row } from "../../../styled/bootstrap";
import ProductCard from "../card/Card";
import SectionTitle from "../../ui/section-title/SectionTitle";
import { RelatedProductsWrapper } from "../feed/style";

const RelatedProducts = ({ products, limit, className, ...props }) => {
  const relatedProducts = products;
  return (
    relatedProducts.length > 0 && (
      <RelatedProductsWrapper {...props} className={cn(className)}>
        <Container>
          <Row>
            <Col xs={12}>
              <SectionTitle
                align="left"
                mb={[27, null, 47]}
                title="Related Products"
              />
            </Col>
          </Row>

          {!relatedProducts && <Loader className="mt-5" />}

          <Row className="products-grid-mobile mtn-30">
            {relatedProducts.slice(0,4).map((product) => (
              <Col xs={6} md={4} lg={3} key={product?.id}>
                <ProductCard product={product} set={false}/>
              </Col>
            ))}
          </Row>
        </Container>
      </RelatedProductsWrapper>
    )
  );
};

RelatedProducts.defaultProps = {
  limit: 4,
};

export default RelatedProducts;
