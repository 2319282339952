import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import Image from "../components/ui/image/Image";
import { Container, Col, Row } from "../styled/bootstrap";
import Breadcrumb from "../components/ui/breadcrumb/Breadcrumb";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout>
      <Breadcrumb
        py={[40, 80]}
        mb={[60, null, 100]}
        pageTitle="About Us"
        path="/about"
      />

      <section className="about-page-wrapper">
        <Container>
          <h2>
            UNICO is the destination for innovative equipment. It is a multi
            brand that supply bathrooms, electrical parts and modern lightnings.
          </h2>
        </Container>

        <Container fluid className="mt-3 mt-md-5">
          <Row>
            <Col md={6}>
              <figure>
                <Image
                  width={950}
                  height={600}
                  alt="about-1"
                  src="/images/about/aboutus.jpg"
                />
              </figure>
            </Col>

            <Col md={6}>
              <figure>
                <Image
                  width={950}
                  height={600}
                  alt="about-2"
                  src="/images/about/aboutus2.jpg"
                />
              </figure>
            </Col>
          </Row>
        </Container>

        <Container className="mt-3 mt-md-5">
          <Row>
            <Col lg={6} className="mb-5 mb-lg-0">
              <div className="about-store">
                <h4>OUR VISION</h4>
                <p>
                  In an ever-developing Cameroon, the vision of UNICO is to
                  provide high end Bathrooms at affordable price.
                </p>
              </div>
            </Col>

            <Col lg={6}>
              <div className="about-store">
                <h4>OUR MISSION</h4>
                <p>
                  TO GIVE PEOPLE THE MOST COMFORTABLE AND AESTHETIC BATHROOMS
                  AND ELECTRIC PARTS
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default AboutPage;
