import {  useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Image from "../ui/image/Image";
import { PostItem, PostContent, PostTitle } from "../blog/post/post.style";
import Select from "../../components/ui/select/Select";
import { Container, Col, Row } from "../../styled/bootstrap";
import ProductCard from "../../components/product/card/Card";
import {
  ShopTopBar,
  ShopTopBarLeft,
  ShopTopBarRight,
  ProductsFeedWrap,
} from "./shop.style";
import EmptyProduct from "../ui/empty/Empty";

export const sortByOptions = [
  { label: "Relevance", value: "relevance" },
  { label: "Name (A - Z)", value: "title-ascending" },
  { label: "Name (Z - A)", value: "title-descending" },
];

const ShopProductsFeed = ({ products }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, sub, search } = useParams();

  const sort = new URLSearchParams(location.search).get("sort");
  const [selectedSort, setSelectedSort] = useState(
    sortByOptions.find((item) => item.value === sort) || sortByOptions[0]
  );

  const onSortHandler = (event) => {
    setSelectedSort({ ...event });
    navigate(`${location.pathname}?sort=${event.value}`);
  };

  if (sort === "title-ascending") {
    products.sort(function (a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
  } else if (sort === "title-descending") {
    products.sort(function (a, b) {
      if (a.title < b.title) {
        return 1;
      }
      if (a.title > b.title) {
        return -1;
      }
      return 0;
    });
  } else {
    products.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
  }

  console.log(
    products.filter(
      (product) =>
        (name &&
          !sub &&
          (product.categories.category.toLowerCase() ===
            name.replaceAll("_", " ").toLowerCase() ||
            product.brand.toLowerCase() ===
              name.replaceAll("_", " ").toLowerCase())) ||
        (name &&
          sub &&
          product.categories.category.toLowerCase() ===
            name.replaceAll("_", " ").toLowerCase() &&
          product.categories.subCategory.toLowerCase() ===
            sub.replaceAll("_", " ").toLowerCase()) ||
        (search &&
          (product.categories.category.toLowerCase() === search.toLowerCase() ||
            product.categories.subCategory.toLowerCase() ===
              search.toLowerCase() ||
            product.title.toLowerCase().includes(search.toLowerCase()) ||
            product.description
              .toLowerCase()
              .includes(search.toLowerCase()))) ||
        (!sub && !name && !search)
    )
  );

  return (
    <ProductsFeedWrap>
      <Container>
        <Row>
          <Col>
            <ShopTopBar>
              <ShopTopBarLeft>
                <p>
                  Showing{" "}
                  {
                    products?.filter(
                      (product) =>
                        (name &&
                          !sub &&
                          (product.categories.category.toLowerCase() ===
                            name.replaceAll("_", " ").toLowerCase() ||
                            product.brand.toLowerCase() ===
                              name.replaceAll("_", " ").toLowerCase())) ||
                        (name &&
                          sub &&
                          product.categories.category.toLowerCase() ===
                            name.replaceAll("_", " ").toLowerCase() &&
                          product.categories.subCategory.toLowerCase() ===
                            sub.replaceAll("_", " ").toLowerCase()) ||
                        (search &&
                          (product.categories.category.toLowerCase() ===
                            search.toLowerCase() ||
                            product.categories.subCategory.toLowerCase() ===
                              search.toLowerCase() ||
                            product.title
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            product.description
                              .toLowerCase()
                              .includes(search.toLowerCase()))) ||
                        (!sub && !name && !search)
                    ).length
                  }{" "}
                  products
                </p>
              </ShopTopBarLeft>

              <ShopTopBarRight>
                <Select
                  label="Sort by"
                  className="sort-by"
                  value={selectedSort}
                  options={sortByOptions}
                  onChange={onSortHandler}
                  classNamePrefix="sort-by"
                />
              </ShopTopBarRight>
            </ShopTopBar>
          </Col>
        </Row>

        <Row
          className="products-grid-mobile"
          style={{ marginTop: "-30px", textAlign: "center" }}
        >
          {products?.filter(
            (product) =>
              (name &&
                !sub &&
                (product.categories.category.toLowerCase() ===
                  name.replaceAll("_", " ").toLowerCase() ||
                  product.brand.toLowerCase() ===
                    name.replaceAll("_", " ").toLowerCase())) ||
              (name &&
                sub &&
                product.categories.category.toLowerCase() ===
                  name.replaceAll("_", " ").toLowerCase() &&
                product.categories.subCategory.toLowerCase() ===
                  sub.replaceAll("_", " ").toLowerCase()) ||
              (search &&
                (product.categories.category.toLowerCase() ===
                  search.toLowerCase() ||
                  product.categories.subCategory.toLowerCase() ===
                    search.toLowerCase() ||
                  product.title.toLowerCase().includes(search.toLowerCase()) ||
                  product.description
                    .toLowerCase()
                    .includes(search.toLowerCase()))) ||
              (!sub && !name && !search)
          ).length > 0 ? (
            name && name.toLocaleLowerCase() === "coycama" ? (
              products
                ?.filter(
                  (product) =>
                    (name &&
                      !sub &&
                      (product.categories.category.toLowerCase() ===
                        name.replaceAll("_", " ").toLowerCase() ||
                        product.brand.toLowerCase() ===
                          name.replaceAll("_", " ").toLowerCase())) ||
                    (name &&
                      sub &&
                      product.categories.category.toLowerCase() ===
                        name.replaceAll("_", " ").toLowerCase() &&
                      product.categories.subCategory.toLowerCase() ===
                        sub.replaceAll("_", " ").toLowerCase()) ||
                    (search &&
                      (product.categories.category.toLowerCase() ===
                        search.toLowerCase() ||
                        product.categories.subCategory.toLowerCase() ===
                          search.toLowerCase() ||
                        product.title
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        product.description
                          .toLowerCase()
                          .includes(search.toLowerCase()))) ||
                    (!sub && !name && !search)
                )
                .map((product) => (
                  <Col xs={6} md={4} lg={3} key={product?.id}>
                    <PostItem style={{marginBottom:"35px"}}>
                      <Link to={`/product/${product.id}`}>
                        <Image
                          alt={product.title}
                          image_hover="image_hover"
                          width={400}
                          height={250}
                          layout="responsive"
                          src={product.images[0]?.originalSrc}
                        />
                      </Link>

                      <PostContent>
                        <PostTitle>
                          <Link to={`/product/${product.id}`}>
                            {product.title}
                          </Link>
                        </PostTitle>
                      </PostContent>
                    </PostItem>
                  </Col>
                ))
            ) : (
              products
                ?.filter(
                  (product) =>
                    (name &&
                      !sub &&
                      (product.categories.category.toLowerCase() ===
                        name.replaceAll("_", " ").toLowerCase() ||
                        product.brand.toLowerCase() ===
                          name.replaceAll("_", " ").toLowerCase())) ||
                    (name &&
                      sub &&
                      product.categories.category.toLowerCase() ===
                        name.replaceAll("_", " ").toLowerCase() &&
                      product.categories.subCategory.toLowerCase() ===
                        sub.replaceAll("_", " ").toLowerCase()) ||
                    (search &&
                      (product.categories.category.toLowerCase() ===
                        search.toLowerCase() ||
                        product.categories.subCategory.toLowerCase() ===
                          search.toLowerCase() ||
                        product.title
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        product.description
                          .toLowerCase()
                          .includes(search.toLowerCase()))) ||
                    (!sub && !name && !search)
                )
                .map((product) => (
                  <Col xs={6} md={4} lg={3} key={product?.id}>
                    <ProductCard product={product} set={false} />
                  </Col>
                ))
            )
          ) : (
            <EmptyProduct />
          )}
        </Row>
      </Container>
    </ProductsFeedWrap>
  );
};

export default ShopProductsFeed;
