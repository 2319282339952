import cn from "classnames";
import { BlogWrap } from "./blog.style";
import Post from "../post/Post";
import { Container, Col, Row, H3 } from "../../../styled/bootstrap";
import Slider, { Slide } from "../../ui/swiper/Slider";
import SectionTitle from "../../ui/section-title/SectionTitle";

const LatestBlog = ({ posts, className, props }) => {
  const settings = {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: false,
    breakpoints: {
      550: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };

  return (
    <BlogWrap {...props} className={cn(className)}>
      <Container>
        <Row>
          <Col>
            <SectionTitle
              mb={52}
              align="center"
              title="Bathroom furniture"
              content="Be inspired by the bathroom furniture collection in our ÚNICO catalogue."
            />
          </Col>
        </Row>

        {posts?.length > 0 ? (
          <Slider settings={settings}>
            {posts?.map((post) => (
              <Slide key={post?.id}>
                <Post post={post} />
              </Slide>
            ))}
          </Slider>
        ) : (
          <div className="text-center">
            <H3>There are no articles found!</H3>
          </div>
        )}
      </Container>
    </BlogWrap>
  );
};

export default LatestBlog;
