import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "../../../styled/bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { SearchBoxWrap, SearchBox, SearchButton } from "./style";
import { OffCanvasCloseBtn } from "../offCanvas/style";

const SearchForm = ({ isShow, onHandler }) => {
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = useState("");

  const onSearchFormHandler = (event) => {
    event.preventDefault();
    onHandler();
    if (searchParam.trim() !== "") navigate(`/search/${searchParam}`);
  };

  const onSearchClick = (url) => {
    onHandler();
    navigate(url);
  };

  const onChangeHandler = (event) => {
    setSearchParam(event.target.value);
  };

  return (
    <SearchBoxWrap show={isShow}>
      <SearchBox>
        <div className="form-wrap">
          <Form onSubmit={onSearchFormHandler}>
            <Input
              type="search"
              name="search"
              className="unico-form-control"
              placeholder="Enter your search keyword..."
              onChange={onChangeHandler}
            />
            <SearchButton type="submit" onClick={onSearchFormHandler}>
              <IoSearchOutline />
            </SearchButton>
          </Form>

          <div className="popular-searches">
            <h4>Popular Searches:</h4>
            <ul>
              <li>
                <button
                  onClick={() => onSearchClick("/shop/Bathtubs_and_Panels")}
                >
                  Bathtubs and Panels
                </button>
              </li>
            </ul>
          </div>

          <OffCanvasCloseBtn onClick={() => onHandler()} />
        </div>
      </SearchBox>
      <div className="overlay" onClick={() => onHandler()} />
    </SearchBoxWrap>
  );
};

export default SearchForm;
