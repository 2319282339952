import cn from "classnames";
import Loader from "../../ui/loader/Loader";
import EmptyProduct from "../../ui/empty/Empty";
import SectionTitle from "../../ui/section-title/SectionTitle";
import { Tabs } from "react-tabs";
import { Col, Container, Row } from "@bootstrap-styled/v4";
import CoycamaSets from "../../blog/post/CoycamaSets";
import Slider, { Slide } from "../../ui/swiper/Slider";



const settings = {
  slidesPerView: 1,
  spaceBetween: 0,
  pagination: false,
  breakpoints: {
    550: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
};

const ProductsTab = ({ products, limit = 8, className }) => {
  const data = products.page1;

  return (
    <div className={cn(className)}>
      <Container>
        <Row>
          <Col xs={12}>
            <SectionTitle
              mb={42}
              align="center"
              title="Coycama"
              content="Step into a world of lavishness with bathroom accessories that embody luxury at its finest."
            />
           

          </Col>
        </Row>

        {!data && <Loader />}

        {data && (
          <Tabs>
            {data?.length > 0 ? (
              <Slider settings={settings}>
                {data?.map((set) => (
                  <Slide key={set?.id}>
                    <CoycamaSets set={set} />
                  </Slide>
                ))}
              </Slider>
            ) : (
              <div className="w-100">
                <EmptyProduct />
              </div>
            )}
          </Tabs>
        )}
      </Container>
    </div>
  );
};

export default ProductsTab;
