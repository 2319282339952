import { useEffect, useState } from "react";
import { Container } from "../../../styled/bootstrap";
import { useNavigate } from "react-router-dom";
import {
  BreadcrumbWrap,
  BreadcrumbTitle,
  BreadcrumbNav,
  BreadcrumbNavItem,
  BreadcrumbNavLink,
} from "./breadcrumb.style";

const Breadcrumb = ({ pageTitle, ...props }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (pageTitle) {
      setBreadcrumbs([{ breadcrumb: pageTitle, href: props.path }]);
    } else {
      setBreadcrumbs([{ breadcrumb: "Shop", href: "/shop" }]);
    }
  }, [pageTitle, props.path]);

  if (!breadcrumbs) {
    return null;
  }

  if (!breadcrumbs) {
    return null;
  }

  return (
    <BreadcrumbWrap {...props}>
      <Container>
        {pageTitle && <BreadcrumbTitle>{pageTitle}</BreadcrumbTitle>}

        <BreadcrumbNav>
          <BreadcrumbNavItem>
            <BreadcrumbNavLink onClick={() => navigate("/")}>
              Home
            </BreadcrumbNavLink>
          </BreadcrumbNavItem>
          {breadcrumbs.map((breadcrumb, i) =>
            breadcrumbs.length !== i + 1 ? (
              <BreadcrumbNavItem key={breadcrumb.breadcrumb}>
                <BreadcrumbNavLink href={breadcrumb.href}>
                  {breadcrumb.breadcrumb}
                </BreadcrumbNavLink>
              </BreadcrumbNavItem>
            ) : (
              <BreadcrumbNavItem key={breadcrumb.breadcrumb}>
                {breadcrumb?.breadcrumb.replaceAll(/\?(.*)/g, "")}
              </BreadcrumbNavItem>
            )
          )}
        </BreadcrumbNav>
      </Container>
    </BreadcrumbWrap>
  );
};

export default Breadcrumb;
