import { SectionText, SectionTitleWrap, Title } from "./title.style";
import {AiOutlineArrowRight} from "react-icons/ai"
import { useNavigate } from "react-router-dom";

const SectionTitle = ({ title, content, align, ...props }) => {
  const navigate=useNavigate()
  return (
    <SectionTitleWrap align={align} {...props}>
      <Title>{title }{title === 'Coycama' ?  <AiOutlineArrowRight onClick={()=>navigate("/shop/coycama")} style={{cursor:"pointer", marginLeft:"15px", fontSize:"20px"}}/>  : ""}</Title>
      <SectionText>{content}</SectionText>
    </SectionTitleWrap>
  );
};

SectionTitle.defaultProps = {
  align: "center",
};

export default SectionTitle;
