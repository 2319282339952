import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/ui/breadcrumb/Breadcrumb";
import Contact from "../components/contact/Contact";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout>
      <Breadcrumb
        py={[40, 80]}
        mb={[60, null, 100]}
        pageTitle="Contact"
        path="/contact"
      />

      <Contact />
    </Layout>
  );
};

export default ContactPage;
