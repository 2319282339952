import { Modal, ModalBody, Row, Col } from "../../../styled/bootstrap";
import { OffCanvasCloseBtn } from "../../ui/offCanvas/style";
import ProductDetailsThumb from "../details/Thumbnail";
import ProductDetailsContent from "../details/Content";

const QuickView = ({ product, isOpen, onHandler }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => onHandler()}
      size="lg"
      modalClassName="ht-modal"
    >
      <OffCanvasCloseBtn className="btn-close" onClick={() => onHandler()}>
        x
      </OffCanvasCloseBtn>
      <ModalBody>
        <Row>
          <Col md={6}>
            <ProductDetailsThumb thumbnails={product?.images} />
          </Col>

          <Col md={6}>
            <ProductDetailsContent product={product} />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default QuickView;
