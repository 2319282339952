import cn from "classnames";
import { Link } from "react-router-dom";
import Image from "../../ui/image/Image";
import { PostItem, PostContent, PostTitle } from "./post.style";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const CoycamaSets = ({ set, className, ...props }) => {
  const { title, images, handle } = set;

  return (
    <PostItem className={cn(className)} {...props}>
      <Link to={handle}>
        <Zoom>
          <Image
            alt={title}
            width={400}
            height={250}
            layout="responsive"
            src={images[0]?.originalSrc}
          />
        </Zoom>
      </Link>

      <PostContent>
        <PostTitle>
          <Link to={handle}>{title}</Link>
        </PostTitle>

      </PostContent>
    </PostItem>
  );
};

export default CoycamaSets;
