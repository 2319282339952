import { Container } from "../../../styled/bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  ProductDescReviewWrapper,
  ProductDescReviewContent,
  ProDescription,
} from "./details.style";

const ProductDescriptionReview = ({ product, ...props }) => {
  const { description } = product;

  return (
    <ProductDescReviewWrapper {...props}>
      <Container>
        <Tabs>
          <TabList className="description-review-nav">
            <Tab>Description</Tab>
          </TabList>

          <ProductDescReviewContent>
            <TabPanel>
              <ProDescription>{description}</ProDescription>
            </TabPanel>
          </ProductDescReviewContent>
        </Tabs>
      </Container>
    </ProductDescReviewWrapper>
  );
};

export default ProductDescriptionReview;
