import cn from "classnames";
import { Link } from "react-router-dom";
import styled, { css, devices } from "../../../styled/styled";

const LogoWrap = styled.div`
  &.logo--desktop {
    ${devices.md} {
      display: none;
    }
  }

  &.logo--mobile {
    display: none;

    ${devices.md} {
      display: block;
    }
  }

  a {
    display: inline-flex;
    overflow: hidden;
    position: relative;

    ${({ width }) =>
      width &&
      css`
        width: ${width + "px"};
      `}

    ${({ height }) =>
      height &&
      css`
        height: ${height + "px"};
      `}
  }
`;

const Logo = ({ width, height, src, className }) => {
  return (
    <LogoWrap width={width} height={height} className={cn(className)}>
      <Link to="/">
        <img
          src={src}
          layout="fill"
          loading="eager"
          alt="logo"
          style={{ objectFit: "contain" }}
        />
      </Link>
    </LogoWrap>
  );
};

Logo.defaultProps = {
  width: 172,
  height: 40,
};

export default Logo;
