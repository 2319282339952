const breakpoints = ["576px", "768px", "992px", "1200px", "1400px"];

export const theme = {
  colors: {
    primary: "#ff000d",
    secondary: "#2f333a",
    success: "#10b759",
    info: "#00b8d4",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#e5e9f2",
    light300: "#dee0e4",
    dark: "#3b4863",
    dark2: "#191919",
    darkgray: "#999999",
    text: "#474747",
    text2: "#1b2e4b",
    text3: "#8392a5",
    text4: "#c0ccda",
    heading: "#212121",
    link: "#001737",
    borderDark: "#43474e",
    borderLight: "#ebebeb",
    white: "#ffffff",
    offWhite: "#e7e7e7",
    offWhiteLight: "#f5f5f5",
    black: "#000000",
    green: "#007a58",
    gray50: "#fafbfc",
    gray100: "#f4f5f8",
    gray150: "#F3F4F6",
    gray200: "#e3e7ed",
    gray250: "#e2e2e2",
    gray300: "#cdd4e0",
    gray400: "#b4bdce",
    gray500: "#97a3b9",
    gray600: "#7987a1",
    gray700: "#596882",
    gray800: "#3b4863",
    gray900: "#1c273c",
    gray950: "#637388",
    pink: "#f10075",
    orange: "#fd7e14",
    cyan: "#17a2b8",
    lightblue: "#a5d7fd",
    android: "#7AC157",
    apple: "#B8B8B8",
    behance: "#1869FF",
    codepen: "#000000",
    dribbble: "#EA4C8A",
    google: "#4285F4",
    dropbox: "#007EE5",
    evernote: "#78D525",
    facebook: "#4867AA",
    pinterest: "#BD081B",
    youtube: "#FE0000",
    linkedin: "#007BB6",
    github: "#313131",
    instagram: "#B23A94",
    css3: "#0277BD",
    html5: "#E44D26",
    javascript: "#F9DC3D",
  },
  fonts: {
    body: "Open Sans",
    heading: "Raleway",
    montserrat: "Montserrat",
  },
  fontSizes: {
    body: "16px",
    standard: "14px",
  },
  fontWeights: {
    body: 400,
    medium: 500,
    subHeading: 600,
    heading: 700,
  },
  lineHeights: {
    body: 1.625,
    heading: 1.25,
  },
  radii: {
    sm: "3px",
    md: "5px",
    lg: "8px",
    rounded: "4px",
    circle: "50%",
    pill: "500px",
  },
  shadows: {
    default: "0 0 12px 3px rgba(0, 0, 0, 0.06)",
    sm: "0px -1px 1px 0px rgba(0,0,0, .2)",
    lg: "0 1rem 3rem rgba(0, 0, 0, .175)",
    input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
  },
  breakpoints: [...breakpoints],
  transition: "all 0.4s ease 0s",
};
