import { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import navData from "../../../data/menu/index.json";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Container, Col, Row } from "../../../styled/bootstrap";
import {
  NavbarWrap,
  Nav,
  NavList,
  SubMenu,
  SubSubMenu,
} from "./desktop-nav.style";

const DesktopNav = ({ bg, className }) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  return (
    <NavbarWrap bg={bg} className={cn(className)}>
      <Container>
        <Row>
          <Col>
            <Nav>
              <NavList>
                {navData.map((navItem, index) => (
                  <li
                    key={index}
                    className={navItem.submenu ? "dropdown" : undefined}
                    onMouseEnter={() => {
                      setSelectedCategory("");
                    }}
                  >
                    <Link to={navItem.link}>
                      {navItem.text}
                      {navItem.submenu && <IoIosArrowDown />}
                    </Link>

                    {navItem.submenu && (
                      <SubMenu>
                        {navItem.submenu.map((item) =>
                          item.list.map((subItem, index) => (
                            <li
                              key={index}
                              onMouseEnter={() => {
                                setSelectedCategory(subItem.text);
                              }}
                            >
                              {subItem.submenu &&
                                subItem.text === selectedCategory && (
                                  <SubSubMenu>
                                    {subItem.submenu.map((subSubItem) =>
                                      subSubItem.list.map((subSub, index) => (
                                        <li
                                          key={index}
                                          onMouseEnter={() => {
                                            setSelectedCategory(subItem.text);
                                          }}
                                          onMouseLeave={() => {
                                            setSelectedCategory("");
                                          }}
                                        >
                                          <Link to={subSub.link}>
                                            {subSub.text}
                                          </Link>
                                        </li>
                                      ))
                                    )}
                                  </SubSubMenu>
                                )}
                              <Link to={subItem.link}>
                                <p>{subItem.text}{" "}</p>
                                {subItem.submenu && (
                                  <MdOutlineKeyboardArrowRight />
                                )}
                              </Link>
                            </li>
                          ))
                        )}
                      </SubMenu>
                    )}
                  </li>
                ))}
              </NavList>
            </Nav>
          </Col>
        </Row>
      </Container>
    </NavbarWrap>
  );
};

export default DesktopNav;
