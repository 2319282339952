import Logo from "../../ui/logo/Logo.js";
import { Col, Container, Row } from "../../../styled/bootstrap.js";
import { AiOutlineMenu, AiOutlineSetting } from "react-icons/ai";
import { BsFillFileEarmarkTextFill } from "react-icons/bs";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  ActionItem,
  HeaderAction,
  HeaderActionBtn,
  HeaderBottomWrap,
} from "./Header.style";

const HeaderBottom = ({
  onConfigHandler,
  onSearchBoxHandler,
  onMobileNavHandler,
}) => {
  const navigate = useNavigate();

  return (
    <HeaderBottomWrap>
      <Container>
        <Row className="align-items-center">
          <Col xs={3} lg={3} className="d-lg-none">
            <HeaderActionBtn onClick={() => onMobileNavHandler()}>
              <AiOutlineMenu />
            </HeaderActionBtn>
          </Col>

          <Col xs={5} lg={3} className="text-center text-lg-left">
            <Logo className="logo--desktop" src="/images/logo/logo.png" />

            <Logo
              width={100}
              height={30}
              className="logo--mobile"
              src="/images/logo/logo.png"
            />
          </Col>

          <Col xs={4} lg={3} className="d-lg-none text-right">
            <HeaderAction>
              <ActionItem>
                <HeaderActionBtn onClick={() => onSearchBoxHandler()}>
                  <IoSearchOutline />
                </HeaderActionBtn>
              </ActionItem>

              <ActionItem>
                <HeaderActionBtn onClick={() => onConfigHandler()}>
                  <AiOutlineSetting />
                </HeaderActionBtn>
              </ActionItem>
            </HeaderAction>
          </Col>

          <Col xs={8} lg={9} className="d-none d-lg-block">
            <HeaderAction>
              <ActionItem>
                <HeaderActionBtn onClick={() => onSearchBoxHandler()}>
                  <IoSearchOutline />
                </HeaderActionBtn>
              </ActionItem>

              <HeaderActionBtn
                className="pr-1"
                onClick={() => navigate("/shop")}
              >
                <BsFillFileEarmarkTextFill style={{ width: "18px" }} />
                My Catalogues
              </HeaderActionBtn>
            </HeaderAction>
          </Col>
        </Row>
      </Container>
    </HeaderBottomWrap>
  );
};

export default HeaderBottom;
