import Logo from "../../ui/logo/Logo";
import OffCanvas from "../../ui/offCanvas/OffCanvas";
import { OffCanvasCloseBtn, OffCanvasHead } from "../../ui/offCanvas/style";
import { SettingsFooter } from "./settings.style";
import { IoIosHeart } from "react-icons/io";
import { BiSupport } from "react-icons/bi";

const SettingsSidebar = ({ isOpen, onHandler }) => {
  return (
    <OffCanvas onHandler={onHandler} open={isOpen}>
      <OffCanvasHead>
        <Logo width={100} src="/images/logo/logo.png" />
        <OffCanvasCloseBtn onClick={() => onHandler()}>x</OffCanvasCloseBtn>
      </OffCanvasHead>

      <SettingsFooter>
        <div className="support">
          <BiSupport />
          <a href="tel:+882836272324">+882836272324</a>
        </div> 
        <p>
          © {new Date().getFullYear()}, Unico. Made With <IoIosHeart /> by{" "}
          <a
            href="https://vision-more.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            {" "}
            Vision & More.
          </a>
        </p>
      </SettingsFooter>
    </OffCanvas>
  );
};

export default SettingsSidebar;
