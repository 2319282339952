import { FooterWrap } from "./footer.style";
import FooterWidget from "./FooterWidget";
import FooterBottom from "./FooterBottom";

const Footer = (props) => {
  return (
    <FooterWrap {...props}>
      <FooterWidget bg="secondary" color="white" />
      <FooterBottom bg="heading" />
    </FooterWrap>
  );
};

export default Footer;
