import cn from "classnames";
import HeaderTop from "./Header-top";
import { DesktopNavbar as Navbar } from "../navbar/Navbar";
import { HeaderArea } from "./Header.style";
import HeaderBottom from "./Header-bottom";

const Header = ({
  bg,
  className,
  onSearchBoxHandler,
  onMobileNavHandler,
  onConfigHandler,
}) => {
  return (
    <HeaderArea className={cn("header", className)}>
      <HeaderTop className="d-none d-lg-block" />

      <HeaderBottom
        onConfigHandler={onConfigHandler}
        onSearchBoxHandler={onSearchBoxHandler}
        onMobileNavHandler={onMobileNavHandler}
      />

      <Navbar bg={bg} className="d-none d-lg-block" />
    </HeaderArea>
  );
};

export default Header;
