import { Link } from "react-router-dom";
import Widget from "../../ui/widget/Widget";
import { Container, Col, Row, Ul, Li } from "../../../styled/bootstrap";
import { WidgetWrapper } from "./footer.style";
// import {
//   SiFacebook,
//   SiTwitter,
//   SiLinkedin,
//   SiPinterest,
//   SiYoutube,
// } from "react-icons/si";
import navData from "../../../data/nav/index.json";

const FooterWidget = (props) => {
  return (
    <WidgetWrapper {...props} py={[60, 60, 100]}>
      <Container>
        <Row>
          <Col md={6} lg={4}>
            <Widget title="ABOUT US" mb={[30, null, null, 0]}>
              <p className="about-text">
                UNICO is the destination for innovative equipment. It is a multi
                brand that supply bathrooms, electrical parts and modern
                lightnings.
              </p>

              {/* <SocialIcons mt={15}>
                <Li inline={true}>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiFacebook />
                  </a>
                </Li>
                <Li inline={true}>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiTwitter />
                  </a>
                </Li>
                <Li inline={true}>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiLinkedin />
                  </a>
                </Li>
                <Li inline={true}>
                  <a
                    href="https://youtube.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiYoutube />
                  </a>
                </Li>
                <Li inline={true}>
                  <a
                    href="https://pinterest.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiPinterest />
                  </a>
                </Li>
              </SocialIcons> */}
            </Widget>
          </Col>

          <Col sm={6} lg={2}>
            <Widget title="INFORMATION" mb={[30, null, null, 0]}>
              <Ul className="widget-list">
                <Li>
                  <Link to="/about">About Us</Link>
                </Li>
                <Li>
                  <Link to="/contact">Contact Us</Link>
                </Li>
              </Ul>
            </Widget>
          </Col>

          <Col sm={6} lg={3}>
            <Widget title="MY CATALOGUES" mb={[30, null, null, 0]}>
              <Ul className="widget-list">
                {navData.slice(0, 7).map((navItem, index) => (
                  <Li key={index}>
                    <Link to={"/shop/" + navItem.text.replaceAll(" ", "_")}>
                      {navItem.text}
                    </Link>
                  </Li>
                ))}
              </Ul>
            </Widget>
          </Col>

          <Col sm={6} lg={3}>
            <Widget title="MY CATALOGUES" mb={[30, null, null, 0]}>
              <Ul className="widget-list">
                {navData.slice(7, navData.length - 1).map((navItem, index) => (
                  <Li key={index}>
                    <Link to={"/shop/" + navItem.text.replaceAll(" ", "_")}>
                      {navItem.text}
                    </Link>
                  </Li>
                ))}
              </Ul>
            </Widget>
          </Col>
        </Row>
      </Container>
    </WidgetWrapper>
  );
};

export default FooterWidget;
