const Image = ({image_hover, src, alt, width, height, ...props }) => {
  return (
    <img
      alt={alt}
      src={src}
      width={width}
      className={image_hover}
      height={height}
      placeholder="blur"
      {...props}
    />
  );
};

export default Image;
