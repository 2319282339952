import { IoIosHeart } from "react-icons/io";
import { Container, Col, Row } from "../../../styled/bootstrap";
import { CopyrightText, FooterBottomWrapper } from "./footer.style";

const FooterBottom = ({ bg }) => {
  return (
    <FooterBottomWrapper
      bg={bg}
      pt={[15, null, null, 25]}
      pb={[10, null, null, 20]}
    >
      <Container>
        <Row className="flex-sm-row-reverse">
          <Col md={12}>
            <CopyrightText>
              © {new Date().getFullYear()}, Unico. Made With <IoIosHeart /> by{" "}
              <a className="company-name" href="https://vision-more.com/">
                {" "}
                Vision & More.
              </a>
            </CopyrightText>
          </Col>
        </Row>
      </Container>
    </FooterBottomWrapper>
  );
};

export default FooterBottom;
