import Layout from "../../components/layout/Layout";
import Breadcrumb from "../../components/ui/breadcrumb/Breadcrumb";
import { Fragment } from "react";
import ProductDetailsContent from "../../components/product/details/Detail";
import RelatedProducts from "../../components/product/feed/RelatedProducts";
import ProductDescriptionReview from "../../components/product/details/DescReview";
import products from "../../data/products/index.json";
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";

const ProductDetailsPage = () => {
  const { id } = useParams();

  const product = products.filter((item) => item.id === parseInt(id))[0];

  const relatedProducts = products.filter(
    (item) =>
      item.id !== parseInt(id) &&
      item.categories.category === product.categories.category
  );

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
  }, [id]);

  return (
    <Layout>
      <Breadcrumb
        py={[60, 80]}
        mb={[60, null, 100]}
        pageTitle={product?.title}
      />

      <Fragment>
        <ProductDetailsContent product={product} />

        {product.description && <ProductDescriptionReview product={product} mt={[55, null, 93]} />}

        <RelatedProducts products={relatedProducts} mt={[48, null, 85]} />
      </Fragment>
    </Layout>
  );
};

export default ProductDetailsPage;
