import cn from "classnames";
import parse from "react-html-parser";
import { WidgetItem, WidgetTitle, WidgetBody } from "./widget.style";

const Widget = ({ title, children, className, ...props }) => {
  return (
    <WidgetItem className={cn(className)} {...props}>
      {title && <WidgetTitle>{parse(title)}</WidgetTitle>}
      <WidgetBody>{children}</WidgetBody>
    </WidgetItem>
  );
};

export default Widget;
