import cn from "classnames";
import { Col, Container, Row } from "../../../styled/bootstrap";
import {
  HeaderTopWrap,
  HeaderTopMessage,
} from "./Header.style";

const HeaderTop = ({ className }) => {
  return (
    <HeaderTopWrap className={cn(className)}>
      <Container>
        <Row>
          <Col md={5} lg={3} className="text-center text-md-left">
            <HeaderTopMessage>Welcome to UNICO</HeaderTopMessage>
          </Col>
        </Row>
      </Container>
    </HeaderTopWrap>
  );
};

export default HeaderTop;
